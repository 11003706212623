<template>
  <q-date v-model="date" mask="YYYY-MM-DD" :options="options">
    <div class="column items-end justify-end">
      <q-input
        outlined
        dense
        :placeholder="timePlaceHolder"
        :hint="hintHint"
        v-model="time"
        class="full-width"
        :rules="[validate]"
        mask="fulltime"
      />
      <q-btn v-close-popup label="Close" color="primary" flat />
    </div>
  </q-date>
</template>

<script>
import dayjs from 'dayjs';
import { MASKS } from "@/shared/constants";

export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    timePlaceHolder: {
      type: String,
      default: () => 'Enter time...',
    },
    hintHint: {
      type: String,
      default: () => 'Format: HH:mm:ss',
    },
    options: {
      type: Function,
      default: () => true,
    },
  },
  data: (vm) => ({
    localDateTime: vm.value,
  }),
  methods: {
    fromQDateToCommon(value) {
      return dayjs(value, MASKS.Q_DATE).format(MASKS.DATE);
    },
    setDateTime(value) {
      if (dayjs(value, MASKS.DATETIME, true).isValid()) {
        this.localDateTime = value;
        this.$emit('input', this.localDateTime);
      }
    },
    validate(value) {
      return dayjs(value, MASKS.TIME, true).isValid();
    },
  },
  computed: {
    date: {
      get() {
        const date = dayjs(this.localDateTime, MASKS.DATETIME);
        return date.isValid() ? date.format(MASKS.Q_DATE) : '';
      },
      set(value) {
        if (typeof value !== 'string') return;
        this.setDateTime(`${this.fromQDateToCommon(value)} ${this.time}`);
      },
    },
    time: {
      get() {
        return this.value.split(' ')[1] || '00:00:00';
      },
      set(value) {
        this.setDateTime(`${this.fromQDateToCommon(this.date)} ${value}`);
      },
    },
  },
};
</script>
