<template>
  <div class="row">
    <q-input
      :error="isInvalidate"
      dense
      filled
      v-model="dateBefore"
      clearable
      placeholder="Click on the icon"
      class="col-grow"
      hide-bottom-space
      mask="##.##.#### ##:##:##"
    >
      <template v-slot:prepend>
        <q-icon name="mdi-calendar" class="cursor-pointer">
          <q-popup-proxy transition-show="scale" transition-hide="scale">
            <d-date-calendar
              v-model="dateBefore"
              :options="forBeforeOptionsFn"
            />
          </q-popup-proxy>
        </q-icon>
      </template>
    </q-input>
    <q-input
      :error="isInvalidate"
      dense
      filled
      v-model="dateAfter"
      hide-bottom-space
      clearable
      placeholder="Click on the icon"
      class="col-grow"
      mask="##.##.#### ##:##:##"
    >
      <template v-slot:prepend>
        <q-icon name="mdi-calendar" class="cursor-pointer">
          <q-popup-proxy transition-show="scale" transition-hide="scale">
            <d-date-calendar v-model="dateAfter" :options="forAfterOptionsFn" />
          </q-popup-proxy>
        </q-icon>
      </template>
    </q-input>
  </div>
</template>

<script>
import { DDateCalendar } from '@/shared/ui/date-calendar';
import dayjs from 'dayjs';

export default {
  components: { DDateCalendar },
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    isInvalidate: false,
  }),
  computed: {
    dateBefore: {
      get() {
        return this.value[0];
      },
      set(value) {
        this.$emit('input', [value, this.value[1]]);
      },
    },
    dateAfter: {
      get() {
        return this.value[1];
      },
      set(value) {
        this.$emit('input', [this.value[0], value]);
      },
    },
  },
  watch: {
    value: {
      deep: true,
      handler: function (val) {
        if (val[0] && val[1]) {
          this.isInvalidate = false;
        }
      },
    },
  },
  methods: {
    validate() {
      this.isInvalidate = !this.value[0] || !this.value[1];
      return !this.isInvalidate;
    },
    forBeforeOptionsFn(date) {
      return dayjs().isAfter(dayjs(date, 'YYYY-MM-DD'));
    },
    forAfterOptionsFn(date) {
      if (!this.dateBefore) {
        return this.forBeforeOptionsFn(date);
      }
      return dayjs(this.dateBefore, 'DD.MM.YYYY').isBefore(
        dayjs(date, 'YYYY-MM-DD')
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  gap: 8px !important;
}
</style>
